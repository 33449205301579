import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import MyAccountModal from '@/components/header/right-panel-menu/my-account-modal';
import { FOOTER_ACCESIBILITY } from '@/utils/constants';

import styles from './trust.module.scss';

const TrustSection = () => {
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 col-xs-12">
      <h6 className="links-title">
        <FormattedMessage id="footer.trust.title" />
      </h6>
      <ul className="footer-links">
        <li>
          <a>
            <div onClick={() => setShowModal(true)} className={styles.pointer}>
              <FormattedMessage id="header.myaccount-makepayment" />
            </div>
          </a>
        </li>
        {showModal && (
          <MyAccountModal
            open={showModal}
            onClose={() => setShowModal(false)}
          />
        )}
        <li>
          <Link href={`/${router.locale}/terms-of-service`} prefetch={false}>
            <a>
              <FormattedMessage id="footer.trust.terms" />
            </a>
          </Link>
        </li>
        <li>
          <Link href={`/${router.locale}/privacy`} prefetch={false}>
            <a>
              <FormattedMessage id="footer.trust.policy" />
            </a>
          </Link>
        </li>
        <li>
          <a
            className={styles.pointer}
            href={FOOTER_ACCESIBILITY}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="footer.trust.accessibility" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default TrustSection;
